import Countdown from 'react-countdown';
import styles from './promo2.module.css';

const Promo2 = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>ЧЕРНАЯ ПЯТНИЦА</div>
                    <div className={styles.promo_title}>Секретное<br/> предложение <div>-89%</div></div>
                    <div className={styles.promo_subtitle}>5&nbsp;легендарных программ<br/> Кати Усмановой, которые<br/> больше нигде нельзя купить,<br/> <b>с&nbsp;доступом на&nbsp;1&nbsp;год!</b></div>
                    <div className={styles.mob}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/promo_mob.png" alt="" />
                    </div>
                    <div className={styles.promo_btns}>
                        <div className={styles.promo_btn_mob}>
                            <div className={styles.promo_btn} onClick={scroll}>Забрать программы</div>
                        </div>
                        <div className={styles.promo_btns_text}>
                            <p>Предложение<br/> сгорит через:</p>
                            <div className={styles.timer}>
                                <Countdown date={Date.now() + 1200000} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.promo_remains}>
                        <div className={styles.promo_remains_text}>Осталось мест<br/> по&nbsp;спецпредложению:</div>
                        <div className={styles.promo_remains_number}>{window.lps_settings.other.count}</div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Promo2;