import styles from './what.module.css';

const What = ({scroll2}) => {
    return (
        <div className={styles.what}>
            <div className="container">
                <div className={styles.what_title}>ЧТО ВАС ЖДЕТ<br/> <span>В&nbsp;УНИКАЛЬНОМ НАБОРЕ</span></div>
                <div className={styles.what_subtitle}>Собрали для вас 12&nbsp;элементов, чтобы вы&nbsp;создали фигуру<br/> своей мечты&nbsp;&mdash; стройную, подтянутую и&nbsp;здоровую</div>
                {/* <div className={styles.what_block}>
                    <div className={styles.what_item}>
                        <div className={styles.what_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_1.png" alt="" />
                        </div>
                        <div className={styles.what_item_name}>Что не&nbsp;менее важно, чем тренировки</div>
                        <div className={styles.what_item_text}>Мы&nbsp;дадим вам фундамент в&nbsp;уроках по&nbsp;правильному питанию для максимального эффекта от&nbsp;тренировок</div>
                    </div>
                    <div className={styles.what_item}>
                        <div className={styles.what_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2.png" alt="" />
                        </div>
                        <div className={styles.what_item_name}>Система быстрого и&nbsp;безопасного похудения за&nbsp;5&nbsp;дней</div>
                        <div className={styles.what_item_text}>Мы&nbsp;проведем FMD-марафон по&nbsp;питанию перед стартом тренировок</div>
                    </div>
                    <div className={styles.what_item}>
                        <div className={styles.what_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_3.png" alt="" />
                        </div>
                        <div className={styles.what_item_name}>Чтобы дойти<br/> до&nbsp;результата нужно</div>
                        <div className={styles.what_item_text}>Живое общение с&nbsp;экспертом, поэтому мы&nbsp;даем 12&nbsp;эфиров с&nbsp;нутрициологом с&nbsp;ответами на&nbsp;вопросы по&nbsp;питанию на&nbsp;1&nbsp;год</div>
                    </div>
                    <div className={styles.what_item}>
                        <div className={styles.what_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_4.png" alt="" />
                        </div>
                        <div className={styles.what_item_name}>У&nbsp;меня особый случай, что мне делать?</div>
                        <div className={styles.what_item_text}>Мы&nbsp;составим персональный персональный эффективный план тренировок для каждой ученицы</div>
                    </div>
                    <div className={styles.what_item}>
                        <div className={styles.what_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_5.png" alt="" />
                        </div>
                        <div className={styles.what_tag}>эксклюзив</div>
                        <div className={styles.what_item_name}>Впервые проведём 6&nbsp;мастер-классов с&nbsp;мотивацией от&nbsp;Кати</div>
                        <div className={styles.what_item_text}>С&nbsp;ответами на&nbsp;самые важные вопросы учениц, которые будут решать конкретно ваши проблемы</div>
                    </div>
                </div> */}

                <div className={styles.b2}>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_3.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p><span>13&nbsp;программ тренировок</span></p>
                            <p>Домашние тренировки и&nbsp;курс для зала</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_5.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p><span>6&nbsp;мастер-классов с&nbsp;Катей</span></p>
                            <p>С&nbsp;ответами на&nbsp;вопросы течение 1&nbsp;года</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_4.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p><span>Составление плана тренировок</span></p>
                            <p>Персонально для каждой участницы на&nbsp;1&nbsp;год</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p><span>Похудейте за&nbsp;5&nbsp;дней</span></p>
                            <p>Живой FMD-марафон быстрого и&nbsp;безопасного похудения</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_1.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p><span>Питайтесь 4&nbsp;раза в&nbsp;день и&nbsp;худейте</span></p>
                            <p>4&nbsp;урока по&nbsp;питанию по&nbsp;правилу сбалансированной тарелки</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_3.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p><span>Готовая поддержка нутрициолога</span></p>
                            <p>12&nbsp;эфиров с&nbsp;ответами на&nbsp;вопросы на&nbsp;тему питания</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_5.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>1&nbsp;год доступа</p>
                            <p>ко&nbsp;всем материалам набора</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_10.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Чат на&nbsp;3&nbsp;месяца</p>
                            <p>С&nbsp;кураторами и&nbsp;другими участницами</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_7.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Розыгрыш<br/> поездки в&nbsp;Дубай</p>
                            <p>на&nbsp;двоих и&nbsp;встреча с&nbsp;Катей Усмановой</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_2.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Розыгрыш<br/> Iphone&nbsp;16 Pro</p>
                            <p>и&nbsp;других ценных призов</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_4.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Подписка GymTeam на&nbsp;1&nbsp;год</p>
                            <p>с&nbsp;70+&nbsp;программами тренировок</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_6.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>11&nbsp;полезных<br/> гайдов</p>
                            <p>для&nbsp;поддержания результата</p>
                        </div>
                    </div>
                </div>

                <div className={styles.b3}>
                    <p>Познакомьтесь с&nbsp;каждой составляющей<br/> набора ниже на&nbsp;сайте</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/down.svg" alt="" onClick={scroll2} />
                </div>
            </div>
        </div>
    )
}

export default What;