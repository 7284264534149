import styles from './promo2.module.css';

const Promo2 = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>ТОЛЬКО 400&nbsp;МЕСТ!</div>
                    <div className={styles.promo_title}>Черная<br/> пятница <span>-90%</span></div>
                    <div className={styles.promo_text}>Создайте стройное тело с&nbsp;комплектом из&nbsp;13&nbsp;фитнес-программ и&nbsp;уникальным наполнением от&nbsp;Кати Усмановой</div>
                    <div className={styles.promo_list}>
                        <p>Марафон FMD-питание для похудения<br/> <b>с&nbsp;результатом уже через 5&nbsp;дней</b></p>
                        <p>6&nbsp;мастер-классов с&nbsp;Катей, чтобы быть<br/> <b>мотивированной на&nbsp;пути к&nbsp;идеальной фигуре</b></p>
                        <p>12&nbsp;живых зумов с&nbsp;нутрициологом, которые<br/> <b>решат все ваши проблемы с&nbsp;питанием</b></p>
                    </div>
                    <div className={styles.mob}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/promo_mob2.png" alt="" />
                    </div>
                    <div className={styles.promo_btns}>
                        <div className={styles.promo_btns_text}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/arrow.svg" alt="" />
                            <p>Забирайте эксклюзивный набор на&nbsp;1&nbsp;год!</p>
                        </div>
                        <div className={styles.promo_btn_mob}>
                            <div className={styles.promo_btn} onClick={scroll}>УСПЕТЬ ЗАБРАТЬ ЭКСКЛЮЗИВ</div>
                        </div>
                    </div>
                    <div className={styles.promo_remains}>
                        <div className={styles.promo_remains_text}>Осталось мест<br/> по&nbsp;спецпредложению:</div>
                        <div className={styles.promo_remains_number}>{window.lps_settings.other.count}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promo2;