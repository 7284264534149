import React, { useEffect, useState } from 'react';
import styles from './redirectPrograms.module.css';

export const RedirectPrograms = () => {
    const [isPopup, setIsPopup] = useState(false);
    
    useEffect(() => {
        openPopup()
    }, [])

    function openPopup() {
        setTimeout(()=> {
            setIsPopup(true)
            setTimeLeft(8)
        }, 5000)
    }

    const redirect = () => {
        setTimeout(function(){document.location.href = "https://gymteam.app/bf24_vitrina"},500);
    }

    const [timeLeft, setTimeLeft] = useState(null);
    useEffect(() => {
        if(timeLeft===0){
            setTimeout(function(){document.location.href = "https://gymteam.app/bf24_vitrina"},500);
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const stay = () => {
        setTimeLeft(null)
        setIsPopup(false)
    }
    return (
        <>
        {isPopup &&
            <div className={styles.popup}>
                <div className={styles.popup_block}>
                    <div className={styles.popup_title}>ЧЁРНАЯ ПЯТНИЦА НАЧАЛАСЬ!</div>
                    <div className={styles.popup_text}>Прямо сейчас забирай комплект программ от&nbsp;Кати Усмановой с&nbsp;невероятной выгодой и&nbsp;получи возможность выиграть крутые призы!</div>
                    <div className={styles.popup_btn} onClick={redirect}>УЗНАТЬ ПОДРОБНОСТИ</div>
                    <div className={styles.popup_count}>Вы будете переадресованы автоматически через {timeLeft}...</div>
                    <div className={styles.popup_btn_close} onClick={stay}><img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/closebtn.svg" alt="" /></div>
                </div>
            </div>
        }
        </>
    );
};