import Countdown from "react-countdown";
import styles from './promo2.module.css';

const Promo2 = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>ЧЕРНАЯ ПЯТНИЦА</div>
                    <div className={styles.promo_title}>ВАШ ПОСЛЕДНИЙ<br/> ШАНС ПОХУДЕТЬ<br/> <b>С&nbsp;ВЫГОДОЙ&nbsp;90%</b></div>
                    <div className={styles.promo_text}>13&nbsp;тренировочных программ,<br/> <b>6&nbsp;мастер-классов с&nbsp;Катей<br/> Усмановой</b> и&nbsp;другие бонусы<br/> в&nbsp;эксклюзивном наборе</div>
                    {/* <div className={styles.promo_list}>
                        <p>4&nbsp;бонусных урока по&nbsp;питанию<br/> для максимального эффекта<br/> от&nbsp;тренировок</p>
                        <p>Чат на&nbsp;1,5 месяца с&nbsp;поддержкой кураторов<br/> и&nbsp;другими ученицами</p>
                    </div> */}
                    <div className={styles.mob}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/promo_mob2.png" alt="" />
                    </div>
                    <div className={styles.mob_btn}>
                        <div className={styles.promo_btn} onClick={scroll}>Забрать набор</div>
                    </div>
                    <div className={styles.promo_more}>
                        {/* <div className={styles.promo_remains_more}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/promo_i1.png" alt="" />
                            <p>Забирайте <b>эксклюзивный<br/> набор на&nbsp;1&nbsp;год</b></p>
                        </div> */}
                        <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_text}>Спецпредложение<br/> сгорит через:</div>
                            {/* <div className={styles.promo_remains_number}>{window.lps_settings.other.count}</div> */}
                            <div className={styles.timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promo2;