// import Countdown from 'react-countdown';
import styles from './programs.module.css';

const Programs = ({scroll, renderer, date}) => {
    return (
        <div className={styles.programs}>
            
            <div className="container">
                
                {/* <div className={styles.t1}>НЕ&nbsp;УПУСТИТЕ САМУЮ БОЛЬШУЮ<br/> <span>СКИДКУ 78% НА&nbsp;КОМПЛЕКТ ИЗ&nbsp;13&nbsp;ПРОГРАММ<br/> КАТИ УСМАНОВОЙ</span></div> */}
                <div className={styles.t1}>БУДЬТЕ В&nbsp;ОТЛИЧНОЙ ФОРМЕ ВСЕГДА!</div>
                <div className={styles.st1}>Забирайте пакет из&nbsp;5&nbsp;ликвидированных программ, чтобы быстро и&nbsp;безопасно сбросить лишний вес, сделать попу более округлой и&nbsp;создать идеальную талию</div>
                <div className={styles.img1}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/arrow.svg" alt="" />
                </div>
                <div className={styles.top}>
                    <div className={styles.dts}>
                        <div className={styles.dts_item}>
                            <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/dts1.svg" alt="" /></div>
                            <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 1&nbsp;год</div>
                        </div>
                        <div className={styles.dts_item}>
                            <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/dts2.svg" alt="" /></div>
                            <div className={styles.dts_item_text}>Старт тренировок&nbsp;&mdash; 25&nbsp;ноября</div>
                        </div>
                        {/* <div className={styles.dts_item}>
                            <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts3.svg" alt="" /></div>
                            <div className={styles.dts_item_text}>Чат с&nbsp;куратором&nbsp;&mdash; с&nbsp;18&nbsp;ноября</div>
                        </div> */}
                    </div>
                </div>
                {/* <div className={styles.h_mob}>
                    <div className={styles.second}>
                            <div className={styles.block}>
                                <div className={styles.left}>
                                    <div className={styles.title}>Забирай комплект программ<br/> на&nbsp;всё тело <span>с&nbsp;выгодой&nbsp;78%</span></div>
                                    <div className={styles.subtitle}>Проработай всё тело в&nbsp;своём темпе, чтобы больше<br/> никогда не&nbsp;пришлось худеть к&nbsp;лету&nbsp;&mdash;<br/> у&nbsp;новой тебя будет стройная фигура круглый год</div>
                                </div>
                            </div>      
                    </div>
                </div> */}
            </div>
            <div className={styles.programs_block}>
            
                        <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <div className={styles.programs_img_bg}></div>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/pr_1.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>МАРАФОН <span>СТРОЙНОСТЬ 2.0</span></div>
                                        <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 21&nbsp;день</li>
                                        <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                        <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                        <li><b>График тренировок:</b> ежедневно</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1" name="p1" />
                                        <label htmlFor="p1">Кому подойдет <p>Марафон подойдёт для тех, кто хочет сбросить лишние килограммы, создать подтянутое и&nbsp;рельефное тело в&nbsp;короткие сроки.</p></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <div className={styles.programs_img_bg}></div>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/pr_2.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Для продвинутых</div>
                                            <div className={styles.programs_tag}>Накачать ягодицы</div>
                                            <div className={styles.programs_tag}>Придать рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>Курс <span>Идеальной попы 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>6 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 3&nbsp;месяца</li>
                                        <li><b>Инвентарь:</b> коврик, штанга/бодибар от&nbsp;8&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                        <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                        <li><b>Группы:</b> набор, рельеф, похудение</li>
                                        <li><b>График тренировок:</b> 5&nbsp;тренировок в&nbsp;неделю</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p2" name="p2" />
                                        <label htmlFor="p2">Кому подойдет <p>Курс идеально подойдёт новичкам и&nbsp;тем, кто делал длительный перерыв в&nbsp;тренировках.</p></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <div className={styles.programs_img_bg}></div>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/pr_3.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Для продвинутых</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}><span>Интенсив по&nbsp;похудению</span></div>
                                        <div className={styles.programs_img__info_price}>10 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 3&nbsp;месяца</li>
                                        <li><b>Инвентарь:</b> разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг, стул</li>
                                        <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                        <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p3" name="p3" />
                                        <label htmlFor="p3">Кому подойдет <p>Курс для тех, кто готов погрузиться в&nbsp;интенсивную работу с&nbsp;головой, чтобы получить впечатляющий результат в&nbsp;кратчайшие сроки.</p></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <div className={styles.programs_img_bg}></div>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/pr_4.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Прокачать пресс</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>Курс <span>Идеального живота 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 3&nbsp;месяца</li>
                                        <li><b>Инвентарь:</b> коврик, стул</li>
                                        <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                        <li><b>График тренировок:</b> 5&nbsp;тренировок в&nbsp;неделю</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p4" name="p4" />
                                        <label htmlFor="p4">Кому подойдет <p>Курс для тех, кто хочет укрепить пресс, сжечь жир и&nbsp;увидеть кубики на&nbsp;своём животе. Ученицам доступны рекомендации по&nbsp;питанию по&nbsp;методу БУЧ.</p></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <div className={styles.programs_img_bg}></div>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/pr_5.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Для продвинутых</div>
                                            <div className={styles.programs_tag}>Прокачать пресс</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>Курс <span>Идеального живота 2.0</span></div>
                                        <div className={styles.programs_img__info_price}>6 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 3&nbsp;месяца</li>
                                        <li><b>Инвентарь:</b> коврик, стул, утяжелители</li>
                                        <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                        <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p4" name="p5" />
                                        <label htmlFor="p5">Кому подойдет <p>Курс для качественной постепенной проработки мышц пресса, подойдёт как новичкам, так и&nbsp;профессионалам</p></label>
                                    </div>
                                </div>
                            </div>
                        </div>

             
                </div>

                {/* <div className={styles.pr1}>
                    <div className={styles.pr1_1}>7&nbsp;990&nbsp;₽</div>
                    <div className={styles.pr1_2}>85&nbsp;990&nbsp;₽</div>
                </div>
                <div className={styles.pr11}>
                    <div className={styles.pr1_4}>(от&nbsp;1 331,5&nbsp;₽/мес.)</div>
                    <div className={styles.pr1_3}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/blank.png" alt="" /><p>-91%</p></div>
                </div> */}
                
                <div className={styles.pr2}>
                    <div className={styles.promo_btn} onClick={scroll}>
                        <p>ЗАБРАТЬ НАБОР</p>
                    </div>
                    {/* <div className={styles.pr2_perc}>&mdash;&nbsp;78%</div> */}
                    <div>
                        <div className={styles.pr1}>
                            <div className={styles.pr1_1}>7&nbsp;990&nbsp;₽</div>
                            <div className={styles.pr1_2}>85&nbsp;990&nbsp;₽</div>
                        </div>
                        <div className={styles.pr11}>
                            <div className={styles.pr1_4}>(от&nbsp;1 331,5&nbsp;₽/мес.)</div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.promo_countdown}>
                    <p>Выгода сгорит через:</p>
                    <div className={styles.promo_countdown_timer}>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                </div> */}
                {/* <div className={styles.remains}>Осталось мест: <span>{window.lps_settings.other.count}</span></div> */}
        </div>
    )
}

export default Programs;