import Countdown from "react-countdown";
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from "./start.module.css";
import { useInstance } from "@com.marathonium/web2-utils";
import { useState } from "react";

const Start = ({ renderer }) => {
	const [widget] = useInstance();
	const [form, setForm] = useState(false);
	
	return (
		<div className={styles.oh}>
			<div className={styles.line}>
				<div className={styles.line_text}>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg"
						alt=""
					/>
				</div>
			</div>

			<div className={"scrollhere " + styles.start}>
				<div className="container">
					<div className={styles.block}>
						<div className={styles.pretitle}>
							Такое предложение бывает <b>только раз в&nbsp;году!</b>
						</div>
						<div className={styles.title}>
							Успейте ЗАБРАТЬ 13&nbsp;ПРОГРАММ
							<br /> И&nbsp;эксклюзивные бонусы
							<br /> <span>С&nbsp;МАКСИМАЛЬНОЙ ВЫГОДОЙ&nbsp;90%</span>
						</div>
						<div className={styles.prices}>
							19&nbsp;990&nbsp;<i>₽</i>{" "}
							<em>
								199&nbsp;480&nbsp;<i>₽</i>
							</em>{" "}
							<span>(от&nbsp;1 658&nbsp;₽/мес.)</span>
						</div>
						<div className={styles.remains}>
							<div className={styles.remains_text}>
								Осталось мест на&nbsp;эксклюзивной
								<br /> волне по&nbsp;самой выгодной цене:
							</div>
							<div className={styles.remains_number}>
								{window.lps_settings.other.count}
							</div>
						</div>
						{/* <div className={styles.pretitle}>Такое предложение бывает <b>только раз в&nbsp;году!</b></div> */}
						<div className={styles.dts}>
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts1.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Доступ&nbsp;&mdash; 1&nbsp;год
								</div>
							</div>
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts4.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Старт FMD-марафона&nbsp;&mdash; 18&nbsp;ноября
								</div>
							</div>
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts2.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Старт тренировок&nbsp;&mdash; 25&nbsp;ноября
								</div>
							</div>
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts3.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Чат с&nbsp;куратором&nbsp;&mdash; с&nbsp;15&nbsp;ноября
									по&nbsp;15&nbsp;февраля
								</div>
							</div>
						</div>
						<div className={styles.info_all}>
							<div className={styles.info_all_name}>
								Набор "Черная пятница 2024"
							</div>
							<div className={styles.info_all_prices}>
								19&nbsp;990&nbsp;₽ <em>199&nbsp;480&nbsp;₽</em>{" "}
								<span>от&nbsp;1 658&nbsp;₽/мес.</span>
							</div>
						</div>
						<div className={styles.info_all}>
							<div className={styles.info_all_name}>
								Эксклюзивные  элементы набора:
							</div>
							<div className={styles.info_all_prices}>
								<em>110&nbsp;000&nbsp;₽</em>
							</div>
						</div>
						<div className={styles.info}>
							<div className={styles.info_item}>
								<p>6&nbsp;мастер-классов с&nbsp;Катей</p>
								<span>60&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>Марафон FMD-питание</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>12&nbsp;эфиров с&nbsp;нутрициологом</p>
								<span>30&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>Уроки по&nbsp;питанию перед стартом</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>Составление плана тренировок</p>
								<span>10&nbsp;000&nbsp;₽</span>
							</div>
						</div>
						<div className={styles.info_all}>
							<div className={styles.info_all_name}>
								13&nbsp;программ Кати Усмановой
							</div>
							<div className={styles.info_all_prices}>
								<em>89&nbsp;480&nbsp;₽</em>{" "}
							</div>
						</div>
						<div className={styles.info}>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Стройность 1.0&raquo;
								</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Упругой попы 1.0&raquo;
								</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Упругой попы 2.0&raquo;
								</p>
								<span>7&nbsp;900&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Плоского живота&raquo;
								</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Антицеллюлитный&raquo;
								</p>
								<span>7&nbsp;500&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Убираем лишний жир&raquo;
								</p>
								<span>7&nbsp;900&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Восстановление после родов&raquo;
								</p>
								<span>7&nbsp;900&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Питание&raquo;
								</p>
								<span>10&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Идеальной попы 2.0&raquo;
								</p>
								<span>6&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Идеальной попы 3.0&raquo;
								</p>
								<span>12&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Жиросжигающий&raquo;
								</p>
								<span>7&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Универсальные тренировки на&nbsp;ягодицы</b>
								</p>
								<span>4&nbsp;790&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс для зала. Цикл&nbsp;1</b>
								</p>
								<span>3&nbsp;490&nbsp;₽</span>
							</div>
						</div>
						{/* <div className={styles.info_more}>+ чат с&nbsp;куратором на&nbsp;1&nbsp;месяц</div> */}

						<div className={styles.info_all}>
							<div className={styles.info_all_name}>
								Плюс подарки при покупке
								<br /> в&nbsp;течение 1&nbsp;часа:
							</div>
							<div className={styles.info_all_prices}>
								<p>Бесплатно</p> <em>28&nbsp;568&nbsp;₽</em>
							</div>
						</div>
						<div className={styles.info}>
							<div className={styles.info_item}>
								<p>
									<b>+ чат с&nbsp;куратором на&nbsp;3&nbsp;месяца</b>
								</p>
								<span>14&nbsp;990&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>
										+ подписка на&nbsp;тренировки GymTeam на&nbsp;1&nbsp;год
									</b>
								</p>
								<span>9&nbsp;588&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>+ 11&nbsp;полезных гайдов</b>
								</p>
								<span>3&nbsp;990&nbsp;₽</span>
							</div>
						</div>

						<div className={styles.timer}>
							<Countdown date={Date.now() + 3600000} renderer={renderer} />
						</div>

						{!form && (
							<div className={styles.btn} onClick={() => setForm(true)}>
								Забрать программы
							</div>
						)}

						{form && (
							<div className="presale_blackfriday_start">
								<SalesWidget
									hook={widget}
									initValue={window.lps_settings.forms.blackfriday2024_offer_id}
									id={window.lps_settings.forms.blackfriday2024_id}
									buttonText="Забрать программы"
								/>
							</div>
						)}

						<div className={styles.pay}>
							<div className={styles.pay_item}>
								<img
									src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank1.png"
									alt=""
								/>
								<p>Рассрочка без процентов от&nbsp;1 658&nbsp;₽ в&nbsp;месяц</p>
								<span>
									Вы&nbsp;можете оформить заказ в&nbsp;рассрочку:
									на&nbsp;12&nbsp;месяцев без процентов
									<br /> и&nbsp;предварительного взноса. Только для
									граждан&nbsp;РФ.
								</span>
							</div>
							<div className={styles.pay_item}>
								<img
									src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank2.png"
									alt=""
								/>
								<p>Оплати Долями, без банка, переплат и&nbsp;рисков</p>
								<span>
									Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; разделите
									платёж на&nbsp;4&nbsp;части от&nbsp;4 975&nbsp;₽.
									<br /> Всего на&nbsp;6&nbsp;недель.
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Start;
