import styles from './second.module.css';

const Second = () => {
    return (
        <div className={styles.second}>
            <div className={styles.line}>
                <div className={styles.line_text}>
                    <p>выгода 90%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>выгода 90%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>выгода 90%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>выгода 90%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>выгода 90%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>выгода 90%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    <p>выгода 90%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                </div>
            </div>
        
            {/* <div className="container">
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>ЧАТ<br/> С&nbsp;КУРАТОРОМ</div>
                        <div className={styles.item_t2}>даём доступ в&nbsp;закрытый<br/> чат на&nbsp;1&nbsp;месяц, начиная<br/> с&nbsp;18&nbsp;ноября</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>1<br/> ГОД</div>
                        <div className={styles.item_t2}>сохраняем доступ<br/> к&nbsp;программам</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>11<br/> ГАЙДОВ</div>
                        <div className={styles.item_t2}>дарим суперполезные<br/> гайды в&nbsp;подарок<br/> при покупке</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>ПОДПИСКА<br/> GYMTEAM</div>
                        <div className={styles.item_t2}>дарим при покупке<br/> сроком на&nbsp;3&nbsp;месяца</div>
                    </div>
                </div>
            </div> */}
        
        </div>
        
    )
}

export default Second;