import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Programs from './components/Programs';
import Start from './components/Start';
import Faq from './components/Faq';
import Promo2 from './components/Promo2';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_10_specbf = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Закрытое предложение с выгодой 89%'/>
        <DocumentDescription value='Закрытое предложение с выгодой 89%' />
        <div className='specbf2024'>
            <Promo2 scroll={scroll} renderer={renderer} date={date} />
            <Programs scroll={scroll}/>
            <Start renderer={renderer} date={date} />
            <Faq scroll={scroll} />
            <Footer version={6} isColor={'#262626'} />
        </div>
      </>
    );
};