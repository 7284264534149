import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './start.module.css';
import { useInstance } from "@com.marathonium/web2-utils";
import { useState } from "react";

const Start = ({date, renderer}) => {
    const [widget] = useInstance();
	const [form, setForm] = useState(false);

    return (
        <div className={styles.oh}>

            <div className={('scrollhere ') + styles.start}>
                <div className="container">
                    <div className={styles.block}>
                        <div className={styles.title}>ЗАБЕРИТЕ НАБОР<br/> ИЗ&nbsp;5&nbsp;ФИТНЕС-ПРОГРАММ<br/> С&nbsp;ВЫГОДОЙ&nbsp;89%!</div>
                        <div className={styles.pretitle}>Создайте стройное и&nbsp;красивое тело, чтобы наслаждаться<br/> им&nbsp;круглый год</div>
                        {/* <div className={styles.prices}>19&nbsp;990&nbsp;<i>₽</i> <em>89&nbsp;480&nbsp;<i>₽</i></em> <span>(от&nbsp;1 658&nbsp;₽/мес.)</span></div> */}
                        {/* <div className={styles.remains}>
                            <div className={styles.remains_text}>Осталось мест на&nbsp;эксклюзивной<br/> волне по&nbsp;самой выгодной цене:</div>
                            <div className={styles.remains_number}>{window.lps_settings.other.count}</div>
                        </div> */}
                        {/* <div className={styles.pretitle}>Такое предложение бывает <b>только раз в&nbsp;году!</b></div> */}
                        <div className={styles.dts}>
                            <div className={styles.dts_item}>
                                <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/dts1.svg" alt="" /></div>
                                <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 1&nbsp;год</div>
                            </div>
                            <div className={styles.dts_item}>
                                <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/specbf/dts2.svg" alt="" /></div>
                                <div className={styles.dts_item_text}>Старт тренировок&nbsp;&mdash; 25&nbsp;ноября</div>
                            </div>
                        </div>

                        <div className={styles.timer}>
                            <p>Твоя выгода сгорит через:</p>
                            <Countdown date={Date.now() + 1200000} renderer={renderer} />
                        </div>

                        <div className={styles.info_all}>
                            <div className={styles.info_all_name}>Весь набор программ:</div>
                            <div className={styles.info_all_prices}><em>34&nbsp;900&nbsp;₽</em> 3&nbsp;990&nbsp;₽ </div>
                        </div>
                        <div className={styles.info}>
                            <div className={styles.info_item}>
                                <p>Марафон Стройность 2.0</p>
                                <span>5&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p>Курс Идеальной попы 1.0</p>
                                <span>6&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p>Интенсив по похудению</p>
                                <span>5&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p>Курс Идеального живота 1.0</p>
                                <span>7&nbsp;900&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p>Курс Идеального живота 2.0</p>
                                <span>6&nbsp;000&nbsp;₽</span>
                            </div>
                        </div>

                        {!form && (
							<div className={styles.btn} onClick={() => setForm(true)}>
								Оформить заказ
							</div>
						)}

						{form && (
							<div className="presale_blackfriday_start">
								<SalesWidget
									hook={widget}
									initValue={window.lps_settings.forms.blackfriday2024_spec_offer_id}
									id={window.lps_settings.forms.blackfriday2024_spec_id}
									buttonText="Оформить заказ"
								/>
							</div>
						)}

                        <div className={styles.pay}>
                            <div className={styles.pay_item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank1.png" alt="" />
                                <p>Рассрочка без процентов от&nbsp;665&nbsp;₽ в&nbsp;месяц</p>
                                <span>Ты&nbsp;можешь оформить заказ в&nbsp;рассрочку: на&nbsp;12&nbsp;месяцев без процентов<br/> и&nbsp;предварительного взноса. Только для граждан&nbsp;РФ.</span>
                            </div>
                            <div className={styles.pay_item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank2.png" alt="" />
                                <p>Оплати Долями, без банка, переплат и&nbsp;рисков</p>
                                <span>Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; раздели платёж на&nbsp;4&nbsp;части от&nbsp;997.5&nbsp;₽.<br/> Всего на&nbsp;6&nbsp;недель.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;