import styles from './bonus.module.css';

const Bonus = ({scroll}) => {
    return (
        <div className={styles.bonus}>
            <div className="container">
                {/* <div className={styles.bonus_title}>5&nbsp;УНИКАЛЬНЫХ БОНУСОВ<br/> НА&nbsp;110&nbsp;000&nbsp;<em>₽</em>, <span>КОТОРЫЕ ОБЕСПЕЧАТ<br/> СОЗДАНИЕ ИДЕАЛЬНОЙ ФИГУРЫ В&nbsp;БОЛЕЕ<br/> КОРОТКИЙ СРОК</span></div> */}
                <div className={styles.bonus_title}>5&nbsp;НОВЫХ ЭЛЕМЕНТОВ ЭТОГО НАБОРА<br/> <span><b>НА&nbsp;110&nbsp;000&nbsp;<em>₽</em></b> БЕСПЛАТНО</span></div>
                <div className={styles.bonus_subtitle}>Самые важные элементы для вашего результата в&nbsp;одном комплекте: питание,<br/> тренировки, мотивация. Успейте взять всё разом только в&nbsp;этом предложении<br/> Чёрной пятницы! Таких бонусов никогда не&nbsp;было и&nbsp;больше не&nbsp;планируется</div>
                <div className={styles.bonus_block}>
                    <div className={styles.bonus_item}>
                        <div className={styles.bonus_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/bonus_1.png" alt="" />
                            <div className={styles.bonus_item_img_num}>#1</div>
                            <div className={styles.bonus_item_img_access}>Доступ 1&nbsp;год</div>
                            <div className={styles.bonus_item_img_price}>60&nbsp;000&nbsp;₽</div>
                        </div>
                        <div className={styles.bonus_item_name}>6&nbsp;мастер-классов Кати с&nbsp;ответами на&nbsp;вопросы</div>
                        <p>Мастер-классы будут проходить в&nbsp;течение года, чтобы закрыть все ваши вопросы по&nbsp;питанию, тренировкам и&nbsp;мотивации.</p>
                        <p>Наши кураторы соберут вопросы в&nbsp;чате, а&nbsp;Катя запишет на&nbsp;них подробные ответы в&nbsp;видео формате.</p>
                    </div>
                    <div className={styles.bonus_item}>
                        <div className={styles.bonus_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/bonus_2.png" alt="" />
                            <div className={styles.bonus_item_img_num}>#2</div>
                            <div className={styles.bonus_item_img_tag}>Живой поток</div>
                            <div className={styles.bonus_item_img_access}>Доступ 1&nbsp;год</div>
                            <div className={styles.bonus_item_img_price}>5&nbsp;000&nbsp;₽</div>
                        </div>
                        <div className={styles.bonus_item_name}>Сбросьте до&nbsp;5&nbsp;кг на&nbsp;5-дневном марафоне FMD-питания</div>
                        <p>До&nbsp;старта тренировок мы&nbsp;проведём марафон FMD-похудения с&nbsp;18&nbsp;по&nbsp;22&nbsp;ноября в&nbsp;формате живого потока. Марафон поможет вам безопасно сбросить 2&ndash;5&nbsp;кг и&nbsp;очистить организм в&nbsp;течение 5&nbsp;дней&nbsp;&mdash; под контролем нашего нутрициолога!</p>
                        <p>После мы&nbsp;поможем мягко перейти на&nbsp;основное питание, чтобы вы&nbsp;сохранили результат без откатов.</p>
                    </div>
                    <div className={styles.bonus_item}>
                        <div className={styles.bonus_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/bonus_3.png" alt="" />
                            <div className={styles.bonus_item_img_num}>#3</div>
                            <div className={styles.bonus_item_img_access}>Доступ 1&nbsp;год</div>
                            <div className={styles.bonus_item_img_price}>30&nbsp;000&nbsp;₽</div>
                        </div>
                        <div className={styles.bonus_item_name}>Отвечаем на&nbsp;все ваши вопросы по&nbsp;питанию в&nbsp;течение года</div>
                        <p>Живые эфиры продолжительностью до&nbsp;2&nbsp;часов будут проходить каждый месяц. Вы&nbsp;сможете задать все свои вопросы нутрициологу лично и&nbsp;детально разобраться с&nbsp;темой питания. Это поможет вам легко адаптироваться к&nbsp;рациону, быстро получить результат и&nbsp;сохранить его.</p>
                    </div>
                    <div className={styles.bonus_item}>
                        <div className={styles.bonus_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/bonus_4.png" alt="" />
                            <div className={styles.bonus_item_img_num}>#4</div>
                            <div className={styles.bonus_item_img_access}>Доступ 1&nbsp;год</div>
                            <div className={styles.bonus_item_img_price}>10&nbsp;000&nbsp;₽</div>
                        </div>
                        {/* <div className={styles.bonus_item_name}>Уроки по&nbsp;питанию по&nbsp;правилу сбалансированной тарелки</div> */}
                        <div className={styles.bonus_item_name}>Питайтесь 4&nbsp;раза в&nbsp;день и&nbsp;худейте</div>
                        <p>Мы&nbsp;сразу открываем вам доступ к&nbsp;урокам по&nbsp;питанию, чтобы вы&nbsp;могли изучить меню и&nbsp;закупить продукты до&nbsp;старта тренировок. Будете питаться по&nbsp;методу здоровой тарелки&nbsp;&mdash; сытно, вкусно, полезно, без подсчёта КБЖУ и&nbsp;жёстких ограничений.</p>
                    </div>
                    <div className={styles.bonus_item}>
                        <div className={styles.bonus_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/bonus_5.png" alt="" />
                            <div className={styles.bonus_item_img_num}>#5</div>
                            <div className={styles.bonus_item_img_access}>Доступ 1&nbsp;год</div>
                            <div className={styles.bonus_item_img_price}>10&nbsp;000&nbsp;₽</div>
                        </div>
                        <div className={styles.bonus_item_name}>Составление персонального плана прохождения программ</div>
                        <p>Перед стартом тренировок вы&nbsp;заполните анкету, на&nbsp;основании которой профессиональный тренер составит для вас персональный план тренировок по&nbsp;программам Кати и&nbsp;дополнит его тренировками для поддержания здоровья по&nbsp;программам GymTeam. План составляется на&nbsp;1&nbsp;год.</p>
                        <p>Учтём вашу физическую подготовку, противопоказания и&nbsp;индивидуальные особенности.</p>
                    </div>
                </div>
                <div className={styles.bonus_btn} onClick={scroll}>ПОЛУЧИТЬ УНИКАЛЬНОЕ ПРЕДЛОЖЕНИЕ</div>
            </div>
        </div>
    )
}

export default Bonus;