import styles from './author.module.css';

const Author = () => {
    return (
        <div className={styles.author}>
            <div className="container">
                <div className={styles.author_title}>Катя Усманова поделится<br/> формулой создания<br/> КРАСИВОЙ ФИГУРЫ</div>
                <div className={styles.author_subtitle}>С&nbsp;2015 года создаёт топовые тренировки для идеальных ягодиц, плоского<br/> живота и&nbsp;стройности без жёстких диет. <b>Уже более 290&nbsp;000 участниц</b><br/> тренируются с&nbsp;Катей, ведь она:</div>

                <div className={styles.author_block}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/author.png" alt="" />
                    <div>Автор первых в&nbsp;России<br/> масштабных марафонов<br/> стройности</div>
                    <div>Вице-чемпионка мира<br/> и&nbsp;чемпионка России<br/> по&nbsp;фитнес-бикини</div>
                    <div>Мама 2-х детей. Всего<br/> за&nbsp;100 дней после первых<br/> родов похудела<br/> на&nbsp;20&nbsp;кг и&nbsp;вернулась<br/> в&nbsp;прежнюю форму</div>
                    <div>Профессиональный<br/> фитнес-тренер<br/> с&nbsp;опытом более 15&nbsp;лет</div>
                </div>

                <div className={styles.author_info}>
                    <div className={styles.author_info_item}>
                        <p>&gt;8&nbsp;ЛЕТ</p>
                        <span>развивает индустрию онлайн-фитнеса</span>
                    </div>
                    <div className={styles.author_info_item}>
                        <p>&gt;4&nbsp;000 +</p>
                        <span>учеников со&nbsp;всего мира тренируются с&nbsp;Катей каждый день</span>
                    </div>
                    <div className={styles.author_info_item}>
                        <p>&gt;290&nbsp;000</p>
                        <span>историй успеха, которые начались с&nbsp;мечты о&nbsp;шикарном теле</span>
                    </div>
                    <div className={styles.author_info_item}>
                        <p>9&nbsp;из&nbsp;10</p>
                        <span>участниц получили желаемый результат</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Author;