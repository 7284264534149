import Countdown from 'react-countdown';
import styles from './block1.module.css';

const Block1 = ({renderer}) => {
    return (
        <div className={styles.block1}>
            <div className="container">
                {/* <div className={styles.top}>
                    <div className={styles.top_left}>
                        <div className={styles.t1}>Присоединись<br/> в&nbsp;течение 15&nbsp;минут<br/> <span>и&nbsp;получи 2&nbsp;ценных бонуса</span></div>
                        <div className={styles.st1}>При оплате в&nbsp;течение 15&nbsp;минут ты&nbsp;получишь доступ к&nbsp;чату<br/> с&nbsp;куратором и&nbsp;3&nbsp;полезных гайда</div>
                    </div>
                    <div className={styles.top_right}>
                        <div className={styles.timer1}>
                            <Countdown date={Date.now() + 900000} renderer={renderer} />
                        </div>
                    </div>
                </div> */}

                <div className={styles.t1}>3&nbsp;СУПЕРПОДАРКА НА&nbsp;20&nbsp;000&nbsp;<em>₽</em><br/> <span>ПРИ ПОКУПКЕ В&nbsp;ТЕЧЕНИЕ 1&nbsp;ЧАСА</span></div>
                <div className={styles.timer}>
                    <Countdown date={Date.now() + 3600000} renderer={renderer} />
                </div>
                <div className={styles.st1}>Вас уже ожидают:</div>
                
                <div className={styles.block}>
                    <div className={styles.block_left}>
                        <div className={styles.block_pretitle}>
                            <p>Бонус №1</p>
                            <span><em>7&nbsp;500&nbsp;₽.</em> БЕСПЛАТНО</span>
                        </div>
                        <div className={styles.block_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/gift.png" alt="" />
                        </div>
                        <div className={styles.block_title}>Доступ к&nbsp;чату<br/> с&nbsp;куратором и&nbsp;другими<br/> участниками<br/> на&nbsp;1,5&nbsp;месяца!</div>
                        <div className={styles.block_text}>Покупая программы в&nbsp;течение 1&nbsp;часа<br/> <b>вы&nbsp;получаете доступ в&nbsp;Telegram-чат с&nbsp;куратором с&nbsp;25&nbsp;ноября.</b> Мы&nbsp;впервые даём доступ к&nbsp;чату на&nbsp;целых 1,5&nbsp;месяца, чтобы вы&nbsp;точно втянулись в&nbsp;тренировки и&nbsp;закрыли все волнующие вопросы. А&nbsp;также получали вдохновение и&nbsp;поддержку, чтобы дойти до&nbsp;конца!</div>
                    </div>
                    <div className={styles.block_right}>
                        <div className={styles.block_phone}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/phone2.png" alt="" />
                            <div className={styles.block_phone_1}>
                                <div>1</div>
                                <div>Ответы на&nbsp;любые вопросы<br/> по&nbsp;тренировкам</div>
                            </div>
                            <div className={styles.block_phone_2}>
                                <div>3</div>
                                <div>Поддержка<br/> куратора</div>
                            </div>
                            <div className={styles.block_phone_3}>
                                <div>5</div>
                                <div>Общение<br/> с&nbsp;единомышленницами</div>
                            </div>
                            <div className={styles.block_phone_4}>
                                <div>2</div>
                                <div>Контроль<br/> результатов</div>
                            </div>
                            <div className={styles.block_phone_5}>
                                <div>4</div>
                                <div>Постоянная<br/> мотивация</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Block1;
